<template>
	<div class="container">
		<baseTable
			ref="baseTable"
			@request="getTableData"
			:queryItem="queryItem"
			:columns="tableCols"
			showOrder
			:otherBtns="otherBtns"
			custom
			fetchAllData
		/>
		<!-- 新增编辑单个被评估人 -->
		<el-dialog width="900px" center :title="title" :visible.sync="editVisible">
			<formItem ref="formItemOne" :formItems="formItems" :defaultData="editData" @cancel="editVisible = false" @submit="handleSubmit">
			</formItem>
		</el-dialog>
	</div>
</template>

<script>
/* 字典项配置 */
import baseTable from '@/views/components/components-base-table';
import formItem from '@/views/components/components-form-item/index.vue';
export default {
	name: 'DictionaryConfig',
	components: {
		baseTable,
		formItem
	},
	props: {
		activeTab: {
			type: String
		},
		pageName: {
			type: String
		}
	},
	computed: {
		applyTypeList() {
			return this.$store.state.approval.applyTypeList;
		}
	},
	data() {
		return {
			title: '',
			editVisible: false,
			tableCols: [
				{
					label: '分组id',
					key: 'groupId',
					type: 'input',
					span: 6
				},
				{
					label: '分组名称',
					key: 'groupName',
					type: 'input',
					span: 6
				},
				{
					label: '字典项',
					key: 'options',
					type: 'input',
					span: 6,
					formatter: (val) => val.map(({ dictName }) => dictName).join('; ')
				},
				// { label: '字典名称', key: 'dictName', type: 'input', span: 6 },
				{
					label: '说明',
					key: 'remark',
					type: 'input',
					span: 6
				},
				{
					label: '操作',
					key: 'cz',
					width: '120px',
					fixed: 'right',
					render: (row) => (
						<div style="display:inline-flex;align-items: center;height: 22px">
							<el-button type="text" onclick={(e) => this.handleEdit(row, e)}>
								编辑
							</el-button>
							<el-button type="text" style="color:red" onclick={(e) => this.handleDel(row, e)}>
								删除
							</el-button>
						</div>
					)
				}
			],
			queryItem: [
				{
					label: '分组id',
					key: 'groupId',
					type: 'input',
					span: 4
				},
				{
					label: '分组名称',
					key: 'groupName',
					type: 'input',
					span: 4
				}
				// { label: '字典编码', key: 'dictId', type: 'input', span: 4 },
				// { label: '字典名称', key: 'dictName', type: 'input', span: 4 }
			],

			otherBtns: [
				{
					icon: 'el-icon-plus',
					label: '新增',
					click: () => {
						this.title = '新增';
						this.editVisible = true;
						this.editData = {};
						this.dialogList = [];
					}
				}
			],
			formItems: [
				{
					label: '分组id',
					key: 'groupId',
					type: 'input',
					span: 12,
					rules: () => this.rules.input
				},
				{
					label: '分组名称',
					key: 'groupName',
					type: 'input',
					span: 12,
					rules: () => this.rules.input
				},
				{
					label: '说明',
					key: 'remark',
					type: 'input',
					span: 12
				},
				{
					label: '字典项',
					key: 'options',
					render: () => (
						<div>
							{this.dialogList.map((item, inx) => (
								<el-row>
									<el-col span={8}>
										<el-input placeholder="名称" size="small" v-model={item.dictName}></el-input>
									</el-col>
									<el-col span={2} style="text-align:center">
										-
									</el-col>
									<el-col span={8}>
										<el-input placeholder="值" size="small" v-model={item.dictId}></el-input>
									</el-col>
									<el-col span={4} style="text-align:center;color:red">
										<i
											class="el-icon-delete"
											onClick={() => {
												this.dialogList.splice(inx, 1);
												if (item.id) {
													this.deleteIds.push(item.id);
												}
											}}
										></i>
									</el-col>
								</el-row>
							))}
							<div style="color:#409eff;cursor:pointer;display: inline-block;" onClick={() => this.dialogList.push({})}>
								<i class="el-icon-plus" style="margin-right: 4px"></i>添加项
							</div>
						</div>
					)
				}
			],
			editData: {},
			rules: {
				input: [{ required: true, message: '请输入', trigger: 'change' }],
				select: [{ trigger: 'change', required: true, message: '请选择' }]
			},
			dialogList: [],
			deleteIds: []
		};
	},
	created() {},
	mounted() {
		// getApprovalTypeOnce();
	},
	methods: {
		getTableData(params, callback) {
			if (params.request) {
				this.$delete(params.request, 'pageSize');
				this.$delete(params.request, 'pageNo');
			}
			callback(this.$axios.post('/v1/common/dict/list', params).then((res) => this.tranformDataList(res)));
		},
		tranformDataList(list) {
			const allKeys = [];

			let arr = [];

			list.forEach(({ groupId }) => {
				if (!allKeys.includes(groupId)) {
					allKeys.push(groupId);
				}
			});

			arr = allKeys.map((key) => {
				const { groupId, groupName, remark } = list.find(({ groupId }) => groupId === key);

				return { groupName, groupId, remark, options: list.filter(({ groupId }) => groupId === key) };
			});
			return arr;
		},
		// 点击table行
		rowClick() {},
		handleDel({ options }, e) {
			e.stopPropagation();
			this.$confirm('确认删除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$axios.post('/v1/common/dict/delete', { request: { deleteDictIds: options.map(({ id }) => id) } }).then((res) => {
						if (res.code) {
							return;
						}
						this.$message.success('删除成功');
						this.$refs.baseTable?.handleSearch();
					});
				})
				.catch(() => {});
		},
		handleEdit(row, e) {
			e.stopPropagation();
			this.editVisible = true;
			this.editData = { ...row };
			if (row.options) {
				this.dialogList = [...row.options];
			}
			this.title = '编辑';
		},
		handleSubmit(form) {
			form.validate((valid) => {
				if (valid) {
					const params = [];

					this.dialogList.forEach((item) => {
						if (item.dictId && item.dictName) {
							params.push({ ...item, ...this.editData });
						}
					});
					const fetchList = [this.$axios.post('/v1/common/dict/save', { request: { commonDicts: params } })];

					if (this.deleteIds?.length) {
						fetchList.push(this.$axios.post('/v1/common/dict/delete', { request: { deleteDictIds: this.deleteIds } }));
					}
					Promise.all(fetchList).then((res) => {
						if (res.find(({ code }) => code)) {
							return;
						}
						this.$message.success('请求成功');
						this.editVisible = false;
						this.$refs.baseTable?.handleSearch();
					});
				}
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.container {
	padding: 30px;
}
</style>
<style lang="scss">
.noMarginBottom {
	.el-form-item {
		margin-bottom: 0;
	}
}
</style>
